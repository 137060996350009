#dwv {
  height: 90%; 
}

.layerContainer {
    position: relative; 
    padding: 0;
    margin: auto; 
    text-align: center; 
    width: 500px;
    height: 500px;
}

.drawDiv {
    position: absolute;
    top: 0; 
    pointer-events: none; 
}

.dropBox {
    border: 5px dashed #e8eaf6;
    margin: auto;
    text-align: center; 
    vertical-align: middle; 
}

.dropBox.hover {
    border: 5px dashed #3f51b5; 
}

.imageLayer {
    position: absolute;
    left: 0px; 
    z-index: 0;
}

#textLayer {
    position: absolute;
    left: 0;
    background-color: transparent;
}